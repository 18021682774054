import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CONSTS, EXTERNAL_LINKS } from 'configs'
import { getOS } from "utils/appHelpers";
import Button from "shared/components/Button";
import Iframe from "./components/Iframe";
import { AppLogo } from "shared/components/Icons";

const Instructions = () => {
  const navigate = useNavigate();
  const { name } = useParams();
  const isWin = getOS() === 'win';

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (CONSTS.instructionKeys[name]) {
      setIsReady(true);
    } else {
      navigate('/')
    }
  }, [name, navigate])

  if (!isReady) return null;

  return (
    <div className='container'>
      <div className='pt-30 pb-24'>
        <div className='row mb-25'>
          <div className='col-4'>
            <AppLogo/>
            <h5 className='mt-16'>
              Set up Krisp for<br />
              <span className='text-primary text-capitalize'>{name}</span>
            </h5>
            <p className='mb-29'>
              Follow the steps in the video<br />
              to set up Krisp
            </p>
            <p className='text-sm'>Stuck? Get help!</p>
            <a
              href={`${EXTERNAL_LINKS.helpdesk}&os=${isWin ? 'win' : 'mac'}`}
              target='_blank'
              className='d-block h8 text-undecorated mb-5'
              rel="noopener noreferrer">
              Open helpdesk
            </a>
            <a
              href={EXTERNAL_LINKS.contact_support}
              target='_blank'
              className='d-block h8 text-undecorated mb-0'
              rel="noopener noreferrer">
              Contact support
            </a>
          </div>
          <div className='col-8 d-flex flex-column'>
            <Iframe appName={name} />
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-between'>
          <Button apperance='outline-primary' onClick={() => navigate('/')}>Back</Button>
          <Button apperance='primary' onClick={() => navigate('/success')}>Complete setup</Button>
        </div>
      </div>
    </div>
  );
};

export default Instructions;
