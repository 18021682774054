import { useState } from 'react';
import AppsHeader from "./components/AppsHeader";
import AppsList from "./components/AppsList";
import Button from "shared/components/Button";
import TerminationModal from "shared/components/TerminationModal";

const Apps = () => {
  const [showTerminationModal, setShowTerminationModal] = useState(false);
  return (
    <>
      <div className='container'>
        <div className='pt-24 pb-20'>
          <AppsHeader />
          <AppsList />
          <div className='text-center'>
            <Button
              apperance='text-primary'
              onClick={() => setShowTerminationModal(true)}
            >
              I’m set up
            </Button>
          </div>
        </div>
      </div>
      {showTerminationModal && (
        <TerminationModal onClose={() => setShowTerminationModal(false)} />
      )}
    </>
  );
};

export default Apps;
