import { Routes, Route, Navigate } from 'react-router-dom';
import Apps from './routes/Apps';
import Instructions from './routes/Instructions';
import Success from './routes/Success';

const App = () => {
  return (
    <Routes>
      <Route path='' element={<Apps />} />
      <Route path=':name' element={<Instructions />} />
      <Route path='success' element={<Success />} />
      <Route path='*' element={<Navigate to='' />} />
    </Routes>
  );
}

export default App;
