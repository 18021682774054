import wihoo from 'assets/images/wihoo.svg';
import Button from 'shared/components/Button';

const Success = () => {
  return (
    <div className='container'>
      <div className='pt-60 pb-30 text-center'>
        {/* TODO: Move this image to CDN */}
        <img src={wihoo} alt='Success' />
        <h5>You’re almost done...</h5>
        <p className='mb-24'>
          Krisp will automatically activate as soon as you start a call <br />
          with your connected app.
        </p>
        <Button apperance='primary'>Open Krisp app</Button>
      </div>
    </div>
  );
};

export default Success;
