const Button = ({
  type = 'button',
  apperance = 'primary',
  block,
  className,
  disabled,
  children,
  onClick = () => {},
}) => {
  return (
    <button
      type={type}
      className={`btn btn-${apperance}${block ? ' btn-block' : ''} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
