import { AppLogo } from 'shared/components/Icons';

const AppsHeader = () => {
  return (
    <div className='text-center mb-28'>
      <AppLogo />
      <h5 className='mt-16'>Choose your conferencing app to setup Krisp</h5>
      <p className='mb-0'>
        Krisp works only in combination with other calling apps.<br />
        Connect Krisp to the apps you use for calls in a few easy steps.
      </p>
    </div>
  );
};

export default AppsHeader;
