import { CONSTS } from 'configs';

const Iframe = ({ appName }) => {
  const src = `https://www.youtube.com/embed/${CONSTS.instructionKeys[appName]}`;

  return (
    <div className='flex-fill d-flex flex-column rounded-2 overflow-hidden bg-gray-400'>
      <iframe
        src={src}
        width='100%'
        title='YouTube video player'
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        className='flex-fill'
      />
    </div>
  );
};

export default Iframe;
