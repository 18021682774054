import { useNavigate } from 'react-router-dom';
import { Zoom, Slack, Skype, Hangouts, FaceTime, Webex, Other } from 'shared/components/Icons';

const apps = [
  { name: 'Zoom', key: 'zoom', Icon: Zoom },
  { name: 'Slack', key: 'slack', Icon: Slack },
  { name: 'Skype', key: 'skype', Icon: Skype },
  { name: 'Hangouts', key: 'hangouts', Icon: Hangouts },
  { name: 'Facetime', key: 'facetime', Icon: FaceTime },
  { name: 'Webex', key: 'webex', Icon: Webex },
  { name: 'Other app', key: 'other', Icon: Other },
];

const AppsList = () => {
  const navigate = useNavigate();
  return (
    <div className='row mb-33'>
      {apps.map(({name, key, Icon}) => (
        <div className='col' key={key}>
          <div className='position-relative'>
            <>
              <div className='py-17 px-8'>
                <div className='w-46-px h-46-px mx-auto' />
              </div>
              <p className='h9 mt-8 mb-0 text-center text-gray-600'>{name}</p>
            </>
            <div
              className='app-item bg-white shadow-1 rounded-2 cursor-pointer position-absolute t-0 l-0 r-0 pt-17 px-8'
              onClick={() => navigate(key)}
            >
              <div className='text-center mb-17'>
                <Icon />
              </div>
              <div className='app-title'>
                <p className='h9 mb-0 text-center'>
                  Setup <br />
                  {name}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AppsList;
