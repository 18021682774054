import React from 'react';
import Button from './Button';
import { AppLogoCircle } from './Icons';

const TerminationModal = ({ onClose }) => {
  return (
    <div className='termination-modal' onClick={onClose}>
      <div className='termination-modal-content' onClick={e => e.stopPropagation()}>
        <div className='text-center'>
          <AppLogoCircle />
          <h5 className='mt-15'>
            Did you setup Krisp<br />
            as instructed in the video?
          </h5>
          <p className='mb-0'>
            Did you setup Krisp<br />
            as instructed in the video?
          </p>
        </div>
        <div className='pt-20 text-center'>
          <Button apperance='outline-primary' className='mr-5' onClick={onClose}>Setup later</Button>
          <Button apperance='primary' className='ml-5' onClick={onClose}>Back to setup</Button>
        </div>
      </div>
    </div>
  );
};

export default TerminationModal;
