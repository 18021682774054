export const CONSTS = {
  instructionKeys: {
    zoom: '5A9mp_E-Oto',
    slack: 'B1seXBjIiY8',
    skype: 'I-oGwSYYwKY',
    hangouts: '8gM11EmLhT8',
    facetime: 'QBgBiSa3qS4',
    webex: 'qt0Bp63pDNQ',
    other: 'Nkqf-W89_q8',
  }
}

export const EXTERNAL_LINKS = {
  helpdesk: 'https://stage.api.krisp.ai/v2/resource?type=link&group=help&name=any&locale=en-AM',
  contact_support: 'https://stage.api.krisp.ai/v2/resource/chat?user_id=null&locale=en-AM'
}